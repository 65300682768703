<template>
  <!-- SECTION Orders-->
  <div id="dashboardSection" class="col-12 justify-content-center" :class="'section-container-lg'">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="row col-12 p-2 m-0 section-container justify-content-center">
        <!-- HEADER Orders -->
        <!--<div class="col-lg-3 col-12 text-white pb-4">
          <div class="col-12 bg-lat rounded-top-5 justify-content-center">
            <h4 class="tx-left text-white text-nowrap mb-0 ps-1 pt-1">
              <ion-icon name="alert-circle-outline" class="tx-18 me-2 align-bottom"></ion-icon>
               <span class="tx-16">Incidencias</span>
            </h4>
          </div>
          <div class="col-12 bg-lat rounded-bottom-5 pb-3 justify-content-center">

            <div class="col-lg-12 col-md-12 col-12 p-2 pt-3">
              <div class="kpi-card bg-light text-center p-3 rounded sombra">
                <span class="kpi-title tx-14 text-dark">Total de incidencias abiertas:</span>
                <p class="kpi-value tx-24 mt-2 text-dark tx-bold">0</p>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 p-2">
              <div class="kpi-card bg-light text-center p-3 rounded sombra">
                <span class="kpi-title tx-14 text-dark">Tiempo promedio de resolución (días): </span>
                <p class="kpi-value tx-24 mt-2 text-dark tx-bold">0.00</p>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 p-2">
              <div class="kpi-card bg-light text-center p-3 rounded sombra">
                <span class="kpi-title tx-14 text-dark">Porcentaje de incidencias cerradas: </span>
                <p class="kpi-value tx-24 mt-2 text-dark tx-bold">0%</p>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 p-2">
              <div class="kpi-card bg-light text-center p-3 rounded sombra">
                <span class="kpi-title tx-14 text-dark">Porcentaje de incidencias abiertas: </span>
                <p class="kpi-value tx-24 mt-2 text-dark tx-bold">0%</p>
              </div>
            </div>

          </div>
        </div>-->
        <div class="col-lg-12 col-12 text-white pb-1">
          <div class="row col-12 bg-caja m-0 p-0 rounded-5 justify-content-around shadow" 
          style="border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">

              <div class="col-lg-12 col-12 text-white p-0">
                <div class="col-12 justify-content-between d-flex align-items-center" style="display: inherit;">
                  <div class="ms-auto"  v-if="this.session.user.rol === '255'">
                    <button id="btnDisplayEditEraseModal" class="button-ok bg-white text-dark mt-0 me-2 rounded align-middle shadow" 
                    style="width: 24px; max-height: 24px;">
                          <span class="button-text text-dark">
                          <ion-icon :name="editVisualization?'close-circle-outline' : 'create-outline'" class="text-dark pt-0 me-0 tx-18 hydrated md align-bottom" role="img" aria-label="create outline"></ion-icon>
                        </span>
                    </button>
                    <span v-if="editAvailable" class="tx-14 tx-bold me-3 c-pointer text-dark align-middle" @click="toggleEditVisualizationPoints">
                      {{ editVisualization ? dictionary["Descartar"][session.user.language] : dictionary["Editar"][session.user.language] }}
                    </span>
                    <button  v-if="editVisualization" id="btnDisplayEditEraseModal" class="button-ok bg-white text-dark mt-0 me-2 rounded align-middle shadow" 
                    style="width: 24px; max-height: 24px;">
                          <span class="button-text text-dark">
                            <ion-icon  name="save-outline" class="text-dark pt-0 me-0 tx-18 hydrated md align-bottom" role="img" aria-label="save-outline"></ion-icon>
                        </span>
                    </button>                   
                    <span v-if="editVisualization" class="tx-14 tx-bold me-3 c-pointer text-dark align-middle" @click="saveChanges">
                      {{  dictionary["Guardar cambios"][session.user.language] }}
                    </span>
                  </div>
                </div>

                <div class="image-container position-relative" ref="imageContainer">
                  <img v-if= "displayedImage.length>1" class="img-fluid p-3 pt-1 rounded-5" :src="displayedImage" />
                  <img  v-else class="img-fluid p-3 pt-1 rounded-5"  src="@/assets/img/placeholder_image.jpg" />

                  <!-- Interactive points -->
                  <div v-for="(machine, index) in machineList" :key="index" class="point" :style="getPointStyle(machine)">
                    <img src="@/assets/img/mas-verde.png" alt="Punto de interés" class="point-image"
                      @click="!editVisualization ? toggleTooltip(index) : null"
                      @mousedown="editVisualization ? startDrag(machine, $event) : null" />

                    <!-- Individual Tooltip -->
                    <div v-if="machine.tooltipVisible" class="custom-popup bg-lat" :style="getPointStyle(machine)" style="min-width: 100px; z-index:99;">
                      <span :data-id="machine.id" class="c-pointer tx-bold tx-11" @click="goToLink(machine)">
                        {{ machine.label }}
                      </span>
                      <div class="row m-0 p-0 pb-3 justify-content-between">
                        <text class="w-100 tx-12">{{ machine.emplacement }}</text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
      </div>
    </div>
  </div>
</template>

<script>

// Clomos
import api from '@/services/api';


export default {
  name: "DashboardComponent",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emplacementList: [
      ],
      machineList: [],
      displayedImage: '',
      isDragging: false,
      draggedMachine: null,
      offsetX: 0,
      offsetY: 0,
      editVisualization: false,
      editAvailable: true,
    };
  },
  emits: ["handleHasRol"],
  methods: {
    goToLink(machine) {
      let path = this.$route.path;      
      let newPath = path.replace(/(dashboard)\/?.*/, 'commandCenter');
      newPath += '/' + machine.id;
      this.$router.replace({
        path: newPath
      });
    },
    startDrag(machine) {
      this.isDragging = true;
      this.draggedMachine = machine;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },

    onDrag(event) {
      if (!this.isDragging) return;
      const containerRect = this.$refs.imageContainer.getBoundingClientRect();
      const newLeft = ((event.clientX - containerRect.left) / containerRect.width) * 100;
      const newTop = ((event.clientY - containerRect.top) / containerRect.height) * 100;
      this.draggedMachine.geoJSON.visualization.coordinates = [newLeft, newTop];
    },

    stopDrag() {
      this.isDragging = false;
      this.draggedMachine = null;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },

    async toggleEditVisualizationPoints() {
      this.editVisualization = !this.editVisualization;
      if (!this.editVisualization) {
        this.machineList=[];
        await this.getMachinesDataByEmplacement()
      }
    },
    async saveChanges() {
      try {
        for (const machine of this.machineList) {
          await api.setMachineData(machine);
        }
        await this.getMachinesDataByEmplacement();
        this.toggleEditVisualizationPoints()        
      } catch (error) {
        console.error("Error guardando los cambios:", error);
      }
    },
    getPointStyle(machine) {
      if (
        machine.geoJSON &&
        machine.geoJSON.visualization &&
        Array.isArray(machine.geoJSON.visualization.coordinates) &&
        machine.geoJSON.visualization.coordinates.length >= 2
      ) {
        return {
          left: machine.geoJSON.visualization.coordinates[0] + '%',
          top: machine.geoJSON.visualization.coordinates[1] + '%',
          position: 'absolute',
          cursor: this.editVisualization ? 'move' : 'pointer'
        };
      } else {
        this.editAvailable = false;
        return {
          left: '0%',
          top: '0%',
          position: 'absolute',
          cursor: this.editVisualization ? 'move' : 'pointer',
          display: 'none'
        };
      }

    },
    toggleTooltip(index) {
      this.machineList.forEach((machine, i) => {
        if (i !== index) {
          machine.tooltipVisible = false;
        }
      });
      const clickedPoint = this.machineList[index];
      clickedPoint.tooltipVisible = !clickedPoint.tooltipVisible;
      const event = window.event;
      clickedPoint.tooltipX = event.clientX;
      clickedPoint.tooltipY = event.clientY;
    },
    hideTooltip(index) {
      this.machineList[index].tooltipVisible = !this.machineList[index].tooltipVisible;
    },
    async displayImage(src) {
      if (src != undefined && src != '') {
        try {
          let response = this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
          if (response) this.displayedImage = response

        }
        catch (e) {
          console.warn(e);
        }
      }

    },

    // GET
    async getEmplacementList(callback) {
      const path = this.$route.path;
      const segments = path.split('/');
      let request = {
        nifCif: segments[2],
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);      
      await this.displayImage(this.currentCompany.params.plano)
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    async getMachinesDataByEmplacement(callback) {
      for (let index = 0; index < this.emplacementList.length; index++) {
        
        
     
      let request = {
        emplacementId: this.emplacementList[index].id,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByEmplacement(request);
      this.machineList.push(...api.parseMachinesFromResponse(response));
    
    }
      this.machineList.forEach((machine) => {
        if(!machine.geoJSON.visualization){
          machine.geoJSON.visualization
        }
        machine.tooltipVisible = false;

      });
      if (callback && typeof callback === "function") callback(this.machineList);
    },
   
  },
  async created() {
    await this.getEmplacementList()
    if (this.emplacementList.length > 0) await this.getMachinesDataByEmplacement()
  },
  mounted() {
    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.stopDrag);
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.stopDrag);
  }
};
</script>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}

.point-image {
  width: 20px;
  height: auto;
  position: absolute;
  transform: translate(-50%, -100%);
}

.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;
  z-index: 100;
  pointer-events: none;
  transform: translate(-50%, -100%);
}
</style>