<template>
<!-- Cabeza -->
  <section id="logInSection">
    <div class="m-0 p-0 gradient-background__noise d-flex flex-column" style="min-height: 100vh; height: 100%;">
      
      <div class="col-lg-12 col-md-12 col-11 text-start p-3">
        <img title="Logo" src="@/assets/img/logo.png" class="img-fluid m-auto" style="max-width: 250px;">
      </div>
      
      <div class="row m-0 p-0">
        <div id="ocultar" class="row col-lg-9 col-md-12 col-12 m-0 p-3 pt-0 pb-0 pt-0 text-white">
          <div class="box justify-content-center m-0 p-0">
            <div class="row justify-content-center m-0 p-0">

              <div class="row col-lg-12 col-md-12 col-12 gradient-border-card border-1 border-white border-1 rounded-5 p-0">
                <div class="col-lg-7 col-md-6 col-12 p-3 mt-auto mb-auto">
                  <h6 class="mt-3">
                    Plataforma multisectorial para el control definitivo de <br>la Cadena de Valor de IoT.
                  </h6>
                  <h3 class="mt-3 text-white">
                    Plataforma para la gestión de sensores IoT
                  </h3>
                  <p class="tx-14 mt-3">
                    CLOMOS aporta una visión detallada de lo que está pasando en el sensor en cada instante. A través de una comunicación 
                    “punto a punto”, se eliminan los elementos innecesarios, obteniendo una comunicación bidireccional en ambos sentidos 
                    pudiendo medir y actuar al mismo tiempo.
                  </p>
                </div>
                <img title="Logotipo" src="@/assets/img/hexagonos.png" class="clomos-log-in-logo col-lg-5 col-md-6 col-12 img-fluid m-auto p-3">
              </div>

              <div class="row col-12 m-0 p-0 ps-2 pe-2 pt-3">

                <div class="col-lg-3 col-md-6 m-0 p-0 mb-3" style="max-height: 60px;">
                  <div class="d-flex justify-content-center gradient-border-card border-1 border-white border-1 rounded-5 m-0 ms-2 me-2 p-2">
                    <ion-icon name="hardware-chip-outline" class="tx-26 m-0 p-2 ps-0 align-bottom rounded-4"></ion-icon>
                    <h5 class="mt-2 ms-0">DIPOSITIVOS</h5>
                  </div>
                  <div class="shimmer" style="--delay: -12.5s"></div>
                </div>

                <div class="col-lg-3 col-md-6 m-0 p-0 mb-3" style="max-height: 60px;">
                  <div class="d-flex justify-content-center gradient-border-card border-1 border-white border-1 rounded-5 m-0 ms-2 me-2 p-2">
                    <ion-icon name="construct-outline" class="tx-26 m-0 p-2 ps-0 align-bottom rounded-4"></ion-icon>
                    <h5 class="mt-2 ms-0">FABRICACIÓN</h5>
                  </div>
                  <div class="shimmer" style="--delay: -12.5s"></div>
                </div>

                <div class="col-lg-3 col-md-6 m-0 p-0 mb-3" style="max-height: 60px;">
                  <div class="d-flex justify-content-center gradient-border-card border-1 border-white border-1 rounded-5 m-0 ms-2 me-2 p-2">
                    <ion-icon name="layers-outline" class="tx-26 m-0 p-2 ps-0 align-bottom rounded-4"></ion-icon>
                    <h5 class="mt-2 ms-0">ALMACÉN</h5>
                  </div>
                  <div class="shimmer" style="--delay: -12.5s"></div>
                </div>

                <div class="col-lg-3 col-md-6 m-0 p-0 mb-3" style="max-height: 60px;">
                  <div class="d-flex justify-content-center gradient-border-card border-1 border-white border-1 rounded-5 m-0 ms-2 me-2 p-2">
                    <ion-icon name="reorder-four-outline" class="tx-26 m-0 p-2 ps-0 align-bottom rounded-4"></ion-icon>
                    <h5 class="mt-2 ms-0">LOCKERS</h5>
                  </div>
                  <div class="shimmer" style="--delay: -12.5s"></div>
                </div>

              </div>
              
            </div>
          </div>
        </div>
          
        <div class="col-lg-3 col-md-12 col-12 m-0 p-3 pt-0">
          <div class="login-box border-1 border-white box sombra-izq rounded-5">
            <div class="card-body pt-2 p-3 rounded-5 bg-menu">
<!-- Language -->
              <div class="col-md-12">
                  <div class="ms-auto me-auto mt-3 form-group text-center rounded-5">
                    <h5 class="text-white text-decoration-underline">ACCESO USUARIOS</h5>
                  </div>
              </div>
<!-- FORM Iniciar Sesion -->
              <form v-if="visibility.formLogIn" id="formLogIn" class="form-horizontal text-center">

                <div class="form-group p-3 pt-0 mt-0">
                  <span class="tx-14 tx-white">{{errorMsg}}</span><br>
                  <div class="col-xs-10">
                    <input v-model="formLogIn.userId" id="userId" type="text" autofocus class="p-2 input-white form-control"
                      :placeholder="dictionary['Nombre de usuario'][session.user.language]+'/'+dictionary['E-mail'][session.user.language]">
                  </div>
                </div>

                <div class="form-group p-3 pt-0">
                  <div class="col-xs-10">
                    <input v-model="formLogIn.password" id="password" type="password" autocomplete="off" class="p-2 input-white form-control"
                      @keydown.enter="onLogInRequest"
                      :placeholder="dictionary['Contraseña'][session.user.language]">
                  </div>
                </div>

                <input v-model="formLogIn.rememberMe" @click="formLogIn.rememberMe=!formLogIn.rememberMe" id="rememberMe" type="checkbox">
                <label for="rememberMe" class="ps-1 text-white">{{dictionary['Recuérdame'][session.user.language]}}</label>
                <div class="form-group text-center mt-3">
                  <div class="col-xs-12 justify-content-center d-flex">
                    <button id="btnLogin" @click="onLogInRequest" type="button" class="button-ok gradient-tablas mt-1 rounded-5">
                      <span>
                        <span class="highlight-bg"></span>
                        <span class="button-text">
                        {{dictionary["Iniciar sesión"][session.user.language]}}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <div class="row align-items-center">
                      <div class="ps-0 pt-3">
                        <a @click="toggleRecoveryForm" id="to-recover" class="tx-white">
                          <ion-icon name="lock-open" class="menu-item-icon tx-14"></ion-icon>
                          {{dictionary["¿Has olvidado tu contraseña?"][session.user.language]}}
                        </a>
                      </div>
                      <div class="ps-0 pt-3">
                        <a @click="toggleValidateClientForm" id="to-validate" class="tx-white">
                          <ion-icon name="checkmark-circle" class="menu-item-icon tx-14"></ion-icon>
                          {{dictionary["Validar cuenta"][session.user.language]}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center">
                  <div class="mt-3 p-3">
                    <label for="language" class="w-100 p-1 text-white">
                    {{dictionary["Idioma"][session.user.language]}}
                    </label>
                    <select 
                      :value="session.user.language"
                      id="languageSelector" class="p-2 input-white form-control"
                      @change="toggleLanguage" 
                      name="languageSelector" title="Language">
                      <option value="es">
                        {{dictionary["Español"][session.user.language]}}
                      </option>
                      <option value="en">
                        {{dictionary["Inglés"][session.user.language]}}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
<!-- FORM Recuperar password -->
              <form v-if="visibility.formPasswordLost" id="formPasswordLost" class="form-horizontal">
                <div class="form-group ">
                  <div class="col-xs-12">
                    <h3 class="tx-white tx-14 mt-3">
                      {{dictionary["Recuperar contraseña"][session.user.language]}}
                    </h3>
                    <p class="tx-white tx-13">
                      {{dictionary["Introduce un correo electrónico al que enviar instrucciones."][session.user.language]}}
                    </p>
                  </div>
                </div>
                <div class="form-group ">
                  <div class="col-md-12 p-3 pt-0">
                    <input v-model="formPasswordLost.email" placeholder="Email" id="inputPasswordLost" 
                    class="p-2 input-white form-control" type="text" required>
                  </div>
                </div>
                <div class="form-group text-center m-t-20">
                  <div class="col-md-12 col-12 justify-content-center d-flex">
                    <button id="btnRePass" class="button-ok gradient-tablas mt-1 rounded-5" type="button"
                      @click="onPasswordLost">
                      <p class="button-text tx-16 pt-2 mt-1">{{dictionary["Recuperar contraseña"][session.user.language]}}</p>
                    </button>
                  </div>
                  <div class="col-md-12 justify-content-center d-flex mt-3">
                    <button id="btnBackToLogIn" @click="toggleRecoveryForm" class="button-ok gradient-tablas mt-1 rounded-5" type="button">
                      <p class="button-text tx-16 pt-2 mt-1">{{dictionary["Volver"][session.user.language]}}</p>
                    </button>
                  </div>
                </div>
              </form>
<!-- FORM Validar email -->
              <form v-if="visibility.formValidateClient" id="formValidateClient" class="form-horizontal">
                <div class="form-group ">
                  <div class="col-md-12 col-12 justify-content-center">
                    <h3 class="tx-white tx-14 mt-3">
                      {{dictionary["Validar cuenta"][session.user.language]}}
                    </h3>
                  </div>
                </div>
                <div class="form-group ">
                  <div class="mb-2 col-md-12">
                    <p class="tx-white tx-13 mt-3">
                      {{dictionary["Completa el siguiente formulario para proceder con tu validación como usuario."][session.user.language]}}
                    </p>
                  </div>
                </div>
                <div class="mt-2 p-3">
                  <div class="form-label-group p-1">
                    <input v-model="formValidateClient.token" :placeholder="dictionary['Token'][session.user.language]" id="inputToken" type="text" class="p-2 input-white form-control mb-3">
                    <label for="inputToken"></label>
                  </div>
                  <div class="form-label-group p-1">
                    <input v-model="formValidateClient.password" :placeholder="dictionary['Contraseña'][session.user.language]" id="tokenPass" type="password" class="p-2 input-white form-control mb-3" required>
                    <label for="tokenPass"></label>
                  </div>
                  <div class="form-label-group">
                    <input v-model="formValidateClient.passwordRe" :placeholder="dictionary['Repite la contraseña'][session.user.language]" id="tokenRePass" type="password" class="p-2 input-white form-control mb-3" required>
                    <label for="tokenRePass"></label>
                  </div>
                  <div class="col-md-12 col-12 justify-content-center d-flex mt-2">
                    <button id="btnValidarCuenta" class="button-ok gradient-tablas mt-1 rounded-5" type="button"
                      @click="onValidateAccount">
                      <p class="button-text tx-16 pt-2 mt-1"> {{dictionary["Validar cuenta"][session.user.language]}}</p>
                    </button>
                  </div>
                  <div class="col-md-12 col-12 justify-content-center d-flex mt-3">
                    <button id="btnBackToLogIn" @click="toggleValidateClientForm" type="button" class="button-ok gradient-tablas mt-1 rounded-5">
                      <p class="button-text tx-16 pt-2 mt-1">{{dictionary["Volver"][session.user.language]}}</p>
                    </button>
                  </div>
                </div>
              </form>
              
            </div>
          </div>
        </div>

      </div>

<!-- Pié -->

    </div>
  </section>
</template>

<script>
// Clomos
import api from '@/services/api';

export default {
  name: "LogInView",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    validationToken: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      errorMsg: "",
      formLogIn: {
        userId: localStorage.getItem("ClomosUserId") ||"",
        password: "",
        rememberMe: localStorage.getItem("ClomosRememberMe") ||true,
      },
      formPasswordLost: {
        email: "",
      },
      formValidateClient: {
        token: "",
        password: "",
        passwordRe: "",
      },
      visibility: {
        formLogIn: true,
        formPasswordLost: false,
        formValidateClient: false,
      }
    }
  },
  emits: ["logInRequest", "toggleLanguage"],
  methods: {
    // DISPLAY
    toggleLanguage(event) {
      this.$emit('toggleLanguage',event.target.value);
    },
    toggleRecoveryForm() {
      if (this.visibility.formPasswordLost) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      } 
      else {
        this.visibility.formPasswordLost = true;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = false;
      }
    },
    toggleValidateClientForm() {
      if (this.visibility.formValidateClient) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      } 
      else {
        this.visibility.formValidateClient = true;
        this.visibility.formPasswordLost = false;
        this.visibility.formLogIn = false;
      }
    },
    // GET
    onLogInRequest() {
      this.$emit('logInRequest',this.formLogIn, (response) => {
        if (response["status-code"] == undefined) {
          this.formLogIn.password = "";
          this.errorMsg = "Error de conexión.";
        }
        else if (response.session.user != undefined) {
          let path = "/"+response.session.user.language+'/'+response.session.user.nifCif+'/general/profile/';
          this.$router.replace({path: path});
        }
        else if (response["status-code"] === "401") {
          this.formLogIn.password = "";
          this.errorMsg = "Credenciales incorrectas."
        }
        else if (response["status-code"] === "500") {
          this.formLogIn.password = "";
          this.errorMsg = "Error en el servidor.";
        }
        else {
          this.formLogIn.password = "";
          this.errorMsg = "Límite de tiempo agotado.";
        }
        clearTimeout(this.errorMsgTimeout);
        this.errorMsgTimeout = setTimeout(()=>{
          this.errorMsg="";
        },5000)
      });
    },
    // SET
    async onValidateAccount() {
      if(this.formValidateClient.password != this.formValidateClient.passwordRe) return;
      let response = await api.validateAccount(this.formValidateClient);
      if (response) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      }
      else {
        this.formValidateClient.password = "";
        this.formValidateClient.passwordRe = "";
      }
    },
    async onPasswordLost() {
      let response = await api.passwordLost(this.formPasswordLost.email);
      if (response) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      }
    },
  },
  created() {
    if (this.validationToken != "") {
      this.formValidateClient.token = this.validationToken;
      this.toggleValidateClientForm();
    }
  }
}
</script>


