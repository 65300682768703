<template>
  <!-- SECTION Status-->
  <div id="statusSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3" style="overflow: hidden;">
          <div class="position-sticky col-12 m-0 p-1 ps-4 bg-head text-white text-start border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-lg-12 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="pulse" class="tx-18 me-2 align-bottom"></ion-icon>
                    <span>{{ dictionary["Estado"][session.user.language] }}</span>
                    <span style="display: none;" v-if="deviceData.label != undefined"> : {{ deviceData.label }} </span>
                    <span v-else> {{ ": " + dictionary['No hay dispositivos disponibles'][session.user.language] }}
                    </span>
                  </h6>
                  <select v-model="targetDeviceId" id="selectTargetDevice" data-live-search="true"
                    @change="targetDevice(targetDeviceId)" class="form-control m-0 p-0 text-nowrap bg-white border"
                    style="border: solid 2px white ; color: black; min-width:200px; max-width: 300px;">
                    <option v-for="device in deviceList" :key="device['id']" :value="device['id']">
                      {{ device['label'] }}
                    </option>
                  </select>

                  <h6 class="ms-4 tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="git-network-outline" class="tx-24 me-2 align-bottom"></ion-icon>
                    <span class="pe-3"> {{ dictionary["Relaciones"][session.user.language] }} </span>
                    <span class="w-100" v-if="!deviceData.children || deviceData.children.length === 0">
                      {{ dictionary["No tiene relaciones"][session.user.language] + "." }}
                    </span>
                    <ion-icon v-if="deviceData.children && deviceData.children.length > 0"
                      name="arrow-down-outline"></ion-icon>
                  </h6>



                </div>
              </div>
              <!-- Fin Primera sección-->

            </div>
          </div>

          <div class="col-12 m-0 p-2 overflow-show justify-content-center">
            <div v-show="deviceData.children&&deviceData.children.length>0" class="border rounded d-flex flex-row justify-content-center ">
              <div v-for="child in deviceData.children" :key="child.idDcl" class="p-3" style="min-width: 200px;">
                <div class="d-flex flex-row justify-content-center rounded-top-3 p-1 tx-bold" :style="{
                  backgroundColor: (child.claseDcl === '2') ? '#3498db' :
                    (child.claseDcl === '3') ? '#2ecc71' :
                      (child.claseDcl === '4') ? '#e67e22' :
                        (child.claseDcl === '7') ? '#e74c3c' :
                          '#f39c12'
                }">
                  <ion-icon
                    :name="(child.claseDcl === '2') ? 'cog-outline' : (child.claseDcl === '3') ? 'hardware-chip-outline' : (child.claseDcl === '4') ? 'grid-outline' : (child.claseDcl === '7') ? 'layers-outline' : ''"
                    class="tx-18 m-auto ms-0 me-2 align-bottom text-white"></ion-icon>
                  <span class="text-white tx-14">
                    {{ child.etiqueta }}
                  </span>
                </div>

                <div
                  class="d-flex flex-row justify-content-center space-around rounded-bottom-3 border-1 p-1 tx-dark bg-white">
                  <span class="m-auto">
                    {{ child.nombreTipo }}
                  </span>
                  <ion-icon name='eye-outline' :data-id="child.idDcl" class='btnGoToEntity btn ms-auto tx-18 tx-primary'
                    @click="onGoToEntity(child)"></ion-icon>
                </div>
              </div>
            </div>
            <div v-if="targetDeviceId != ''" class="col-12 d-flex flex-wrap m-0 p-2">

              <!-- CARD Variables -->
              <div class="col m-0 p-0 d-flex flex-wrap" style="height: fit-content;">
                <div v-for="variableOptions in statusGaugeChartOptions" :key="variableOptions" id="cardVariables"
                  class="col-lg-2 col-md-3 col-12 m-0 p-2 pb-1">
                  <h6 class="bg-menu rounded-top-5 p-1 mb-0 text-white tx-12">
                    {{ variableOptions.series[0].data[0].name }}
                  </h6>
                  <div class="border-1 m-0 p-0 pb-1 rounded-bottom-3">
                    <VueEcharts :option="variableOptions"
                      :id="variableOptions.series[0].data[0].name + 'GaugeChartCanvas'"
                      class="variableGaugeChart ps-0 pe-0" style="height: 7rem;">
                    </VueEcharts>
                    <div class="m-0 p-0" style="margin-top:-10px !important">
                      <span class="tx-12 tx-bold text-dark"> {{ dictionary['Fecha'][session.user.language] + ": " }}
                      </span>
                      <span class="tx-12"> {{ this.$dayjs(new
                        Date(variableOptions.valueDateTime)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat)
                        }}
                      </span>
                    </div>
                    <div class="m-0 p-0" style="margin-top:-10px !important">
                      <span class="tx-12 tx-bold text-dark"> {{ dictionary['Hora'][session.user.language] + ": " }}
                      </span>
                      <span class="tx-12"> {{ this.$dayjs(new
                        Date(variableOptions.valueDateTime)).utc("z").local().tz(this.session.user.dateTimeZone).format("HH:mm")
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- CARD Last Day -->
              <div id="cardLastDay" class="col m-0 p-2">
                <h6 class="bg-lat tx-12 rounded-top-5 p-1 mb-0 text-white">
                  <span>{{ dictionary['Último día'][session.user.language] }}</span>
                </h6>
                <div class="border-1 pt-3 pb-3 m-0 rounded-bottom" style="overflow: none;">
                  <div class="m-0 p-0" style="height: 30rem;">
                    <VueEcharts :v-if="lastDayLineChartOptions[0] != undefined" :option="lastDayLineChartOptions"
                      style="height: 30rem;" id="lastDayChartCanvas" class="col-12 ps-0 pe-0">
                    </VueEcharts>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="m-3 p-3 border rounded">
              <span class="tx-13">
                {{ dictionary['No hay dispositivos disponibles'][session.user.language] }}
              </span>
            </div>


          </div>

        </div>
      </div>


    </div>
  </div>

</template>

<script>
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
// Clomos
import api from '@/services/api';

export default {
  name: "InstallationStatusComponent",
  components: { VueEcharts },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    requestedDeviceId: {
      type: String,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    }
  },
  watch: {
    $route() {
      if (this.$route.params.targetEntity === "" || this.$route.params.targetEntity === this.targetDeviceId) return;
      this.targetDevice(this.$route.params.targetEntity);
    },
    currentCompany: async function () {
      await this.getDeviceListByCompany();
      if (this.deviceList[0]) {
        this.targetDeviceId = this.deviceList[0].id;
        await this.getDeviceData();
      }
      this.variableHistory = {};
      await this.getDeviceVariableHistory();
    },
    requestedDeviceId: async function (newId) {
      this.targetDevice(newId);
    }
  },
  data() {
    return {
      // LAST DAY CHART
      lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
      // GAUGE CHARTS
      gaugeChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["gaugeChartOptions"],
      deviceData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        children: [],
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {}
        }
      },
      variableHistory: {},
      deviceList: [],
      targetDeviceId: this.$route.params.targetEntity,
      // COLORS
      colors: ["#9e9e9e",
        "#4e79a7",
        "#f28d2b",
        "#e05759",
        "#76b7b2",
        "#59a14f",
        "#af7aa1",
        "#b1de89",
        "#595055",
        "#edc948",
        "#1c8356",
        "#ff9da7",
        "#8d9fca",
        "#2f451e"]
    }
  },
  computed: {
    fractionalLength() {
      return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
    },
    // DIGITAL GAUGE CHARTS
    statusGaugeChartOptions() {
      let statusGaugeChartOptions = {};
      let rawDeviceData = JSON.parse(JSON.stringify(this.deviceData));
      if (rawDeviceData.variables === undefined) return statusGaugeChartOptions;
      for (let i in rawDeviceData.variables.analog) {
        if (rawDeviceData.variables.analog[i].isConfiguration === 'false') {
          statusGaugeChartOptions[i] = JSON.parse(JSON.stringify(this.gaugeChartOptions));
          statusGaugeChartOptions[i].series[0].detail.formatter = "{value} " + rawDeviceData.variables.analog[i].unit;
          if (rawDeviceData.variables.analog[i].unit === "%") {
            statusGaugeChartOptions[i].series[0].max = 100;
            statusGaugeChartOptions[i].series[0].min = 0;
          }
          else {
            statusGaugeChartOptions[i].series[0].max = Number(rawDeviceData.variables.analog[i].max);
            statusGaugeChartOptions[i].series[0].min = Number(rawDeviceData.variables.analog[i].min);
          }
          statusGaugeChartOptions[i].series[0].data[0] = {
            name: rawDeviceData.variables.analog[i].label.charAt(0).toUpperCase() + rawDeviceData.variables.analog[i].label.slice(1),
            value: Number(rawDeviceData.variables.analog[i].value).toFixed(this.fractionalLength)
          };
          statusGaugeChartOptions[i].valueDateTime = rawDeviceData.variables.analog[i].valueDateTime;
        }
      }
      return statusGaugeChartOptions;
    },
    // LAST DAY CHART
    lastDayLineChartOptions() {
      let lastDayLineChartOptions;
      let now = new Date().getTime();
      let yesterday = new Date().setDate(new Date().getDate() - 1);

      lastDayLineChartOptions = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId === "") return lastDayLineChartOptions;
      lastDayLineChartOptions.axisPointer = {
        label: {
          formatter: (function (dataTarget) {
            let d = new Date(dataTarget.value);
            return String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0");
          })
        },
      }
      lastDayLineChartOptions.xAxis = {
        min: yesterday,
        max: now,
        axisLabel: {
          formatter: (function (value) {
            let d = new Date(value);
            return String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0");
          }),
          textStyle: {
            fontSize: 10
          }
        }
      }
      lastDayLineChartOptions.yAxis = {
        type: "value"
      }
      let i = 0;
      for (let variable in this.variableHistory) {
        lastDayLineChartOptions.legend.data[i] = variable;
        lastDayLineChartOptions.series[i] = {
          data: this.variableHistory[variable],
          name: variable,
          type: "line",
          symbol: "emptyDiamond",
          symbolSize: 6,
          color: this.colors[i],
          smooth: true,
          step: false,
          areaStyle: {
            color: this.colors[i],
            opacity: 0.03
          },
        }
        i += 1;
      }
      return lastDayLineChartOptions;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onGoToEntity(child) {
      let view = "";

      const currentRoute = this.$router.currentRoute.value;

      switch (child.claseDcl) {
        case "3":
          if (currentRoute.params.currentDisplay && currentRoute.params.currentDisplay === "devices") {
            this.$router.replace({ params: { targetEntity: child.idDcl } });
          }
          break;

        case "2":
          if (currentRoute.params.currentModule && currentRoute.params.currentModule === "electricalMonitoring") {
            this.$router.replace({
              params: { currentDisplay: "commandCenter", targetEntity: child.idDcl }
            });
          } else if (currentRoute.params.currentModule && currentRoute.params.currentModule === "fabric"){
            this.$router.replace({
              params: { currentDisplay: "machines", targetEntity: child.idDcl }
            });
          } else if (currentRoute.params.currentModule && currentRoute.params.currentModule === "smartLockers"){
            this.$router.replace({
              params: { currentDisplay: "lockers", targetEntity: child.idDcl }
            });
          }
          view = "machines";
          break;

        case "4":
          view = "bundles";
          break;

        case "7":
          view = "stocks";
          break;

        default:
          view = "";
          break;
      }

      if (view !== "") {
        /*  this.$router.replace({
           params: { currentView: view, targetEntity: child.idDcl }
         }); */
      }
    },
    // GET
    async getDeviceData(callback) {
      let response = await api.getDeviceData(this.targetDeviceId);
      this.deviceData = api.parseDeviceFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceData);
    },
    async getDeviceListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    // TARGET
    async targetDevice(newId) {
      this.targetDeviceId = newId;
      await this.getDeviceData();
      this.$router.replace({ params: { targetEntity: this.targetDeviceId } })
      this.variableHistory = {};
      await this.getDeviceVariableHistory();
    },
    async getDeviceVariableHistory(callback) {
      if (this.deviceData != {}) for (let variableType in this.deviceData.variables) {
        for (let variable in this.deviceData.variables[variableType]) {
          if (this.deviceData.variables[variableType][variable] == undefined || this.deviceData.variables[variableType][variable].saveHistory != "true") continue;
          let request = {
            id: this.deviceData.id,
            variableLabel: this.deviceData.variables[variableType][variable].label,
            variableType: this.deviceData.variables[variableType][variable].type,
            limit: 0,
            offset: 0,
            endDateTime: api.parseDateTimeToString(new Date()),
            startDateTime: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
          };
          let response = await api.getDeviceVariableHistory(request);
          let rawHistory = api.parseVariableHistoryFromResponse(response);
          if (this.deviceData.variables && this.deviceData.variables[variableType] && this.deviceData.variables[variableType][variable]) {
            this.variableHistory[this.deviceData.variables[variableType][variable].label] = [];
          }


          for (let i in rawHistory) {
            this.variableHistory[this.deviceData.variables[variableType][variable].label][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(this.fractionalLength)
            ];
          }
          if (callback && typeof callback === "function") callback(this.deviceList);
        }
      }
    }
  },
  async created() {
    await this.getDeviceListByCompany();
    if (this.$route.params.currentDisplay === 'status') {
      if (this.deviceList[0] != undefined && this.targetDeviceId === "") {
        this.targetDeviceId = this.deviceList[0].id;
        this.$router.replace({ params: { targetEntity: this.targetDeviceId } })
        await this.getDeviceData();
      }
      else this.targetDevice(this.targetDeviceId)
      this.variableHistory = {};
      await this.getDeviceVariableHistory();
    }
    this.targetDevice(this.targetDeviceId)
  }
}
</script>

<style>
#cardRelaysStatusContainer {
  display: flex;
  gap: 1rem;
}

#cardDigitalInputsContainer {
  display: flex;
  gap: 1rem;
}

.btnGoToEntity:hover {
  color: var(--main-dark) !important;
}

@media (max-width: 767px) {

  #cardOverall,
  #cardCurrentStatus,
  #cardSCC {
    padding-right: 1rem !important;
  }

  #cardRelaysStatusContainer,
  #cardDigitalInputsContainer {
    display: block;
  }
}
</style>