<template>
  <!-- SECTION SiteMap -->
  <div id="siteMapSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-lg-3 col-md-3 col-12 m-0 pt-0 ps-3 pe-4 bg-lat text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 bg-lat text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Emplazamientos"][session.user.language] }}
                  </h6>
                </div>
              </div>
              <!-- Fin Primera sección-->
              <div class="row col justify-content-end p-0 m-0 pe-4">
                <span class="text-end text-white mt-1 mb-2 pe-4">
                  <ion-icon name="cog-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                  {{ dictionary["Configuración"][session.user.language] }}
                </span>
              </div>
              <div class="row justify-content-end p-0 m-0 pt-1 pe-4" style="width: 130px !important;">
                <button v-if="this.session.user.rol === '255'" id="btnDisplayNewDeviceModal"
                  style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                  @click="toggleNewEmplacementModal">
                  <span>
                    <span class="button-text text-dark">
                      <ion-icon name="add-circle-outline" class="text-dark pt-0 tx-18 hydrated" data-bs-toggle="tooltip"
                        data-bs-placement="bottom" title="Añadir"></ion-icon>
                    </span>
                  </span>
                </button>

                <button style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                  @click="this.$emit('toggleConfigurationView')">
                  <span>
                    <span class="button-text text-dark">
                      <ion-icon name="return-down-back-outline" class="text-dark pt-0 me-0 tx-18 hydrated"></ion-icon>
                    </span>
                  </span>
                </button>
              </div>
            </div>

          </div>


          <!-- CONTENT table -->
          <div v-show="!displayType.includes('table')"
            class="row col-lg-12 col-md-12 col-12 ps-4 p-3 pt-0 pb-0  mt-2 h-auto">
            <div id="tableContent" class="col-12 bg-caja m-0 p-3 mt-3 mb-1 ms-2 rounded-5 shadow"
              style="border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">
              <table id="emplacementsConfigurationDataTable" class="table table-striped p-0 pb-3 bg-caja overflow-auto">
                <thead class="bg-lat tx-14 p-0 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in emplacementsConfigurationColumns" :key="column.data" :width="column.width"
                      class="fw-medium p-1">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>



          <div v-show="!displayType.includes('cards') && this.targetEmplacementId === ''" class="p-3">
            <span class="tx-13">
              {{ dictionary['No hay ' + ((this.$route.params.currentDisplay === "emplacements") ? 'emplazamientos' :
                'centros de mando') + ' disponibles'][session.user.language]}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- MODAL New Emplacement -->
  <NewEmplacementModal v-if="display.newEmplacementModal" :session="session" :dictionary="dictionary"
    :countries="countries" :currentCompany="currentCompany" v-on:toggleNewEmplacementModal="toggleNewEmplacementModal">
  </NewEmplacementModal>

  <!-- MODAL Edit Emplacement -->
  <EditEmplacementModal v-if="display.editEmplacementModal" :session="session" :dictionary="dictionary"
    :countries="countries" :currentCompany="currentCompany" :emplacementId="targetEmplacementId"
    :editRequest="editRequest" v-on:toggleEditEmplacementModal="toggleEditEmplacementModal">
  </EditEmplacementModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteEmplacementModal" :session="session" :dictionary="dictionary"
    :countries="countries" :alertHeader="dictionary['Emplazamiento a eliminar'][session.user.language] + ': '"
    :alertTarget="targetEmplacementId" v-on:acceptAlert="onDeleteEmplacement"
    v-on:dismissAlert="toggleDeleteEmplacementModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import NewEmplacementModal from '@/components/emplacements/NewEmplacementModal';
import EditEmplacementModal from '@/components/emplacements/EditEmplacementModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "EmplacementsComponent",
  components: {
    NewEmplacementModal, EditEmplacementModal, TextAlertModal
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      await this.getEmplacementList();
      this.displayEmplacementsConfigurationDataTable();
      if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
      else this.targetEmplacementId = "";
      this.setButtonFunctions();
    }
  },
  data() {
    return {
      // MAP
      emplacementsConfigurationColumns: [
        {
          text: "Identificador", data: "emplacementId", width: "20%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnTargetEmplacement c-pointer tx-12 tx-bold tx-clomos-midtone align-middle'>" + data + "</span>";
          }
        },
        {
          text: "Nombre", data: "emplacementLabel", width: "20%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100 btnTargetEmplacement c-pointer'>" + data.label + "</span>"
          }
        },
        {
          text: "Localización", data: "emplacementLocation", width: "20%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data + " class='text-start tx-12 w-100'>" + data + "</span>"
          }
        },
        {
          text: "Tipo", data: "emplacementType", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data + " class='text-start tx-12 w-100'>" + data + "</span>"
          }
        },
        {
          text: "Coordenadas", data: "emplacementGeoJSON", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            if (data.geometry != undefined && data.geometry.type === "Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-primary'>Lat:</span> <span>" + Number(data.geometry.coordinates[0]).toFixed(3) + "</span> <span class='me-2 ms-2 tx-primary'>Lon:</span> <span>" + Number(data.geometry.coordinates[1]).toFixed(3)
            else return "<span class='me-2 ms-2 tx-primary'>Lat:</span> <span>" + 0 + "</span>  <span class='me-2 ms-2 tx-primary'> Lon:</span></div> " + 0
          }
        },
        {
          text: "Editar", data: "emplacementId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id=" + data + " class='btnEditEmplacement btn tx-18 p-0 tx-16 text-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "emplacementId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteEmplacement btn tx-18 p-0 tx-16 text-danger'></ion-icon>"
          }
        }
      ],
      targetEmplacementId: this.$route.params.targetEntity,
      emplacementList: [],
      emplacementsMap: undefined,
      displayType: [],
      emplacementCardsPage: 0,
      display: {
        newEmplacementModal: false,
        editEmplacementModal: false,
        deleteEmplacementModal: false
      },
      fullSize: false
    }
  },
  computed: {
    viewpoint() {
      let viewpoint = {
        center: { lng: 0, lat: 0 },
        zoom: 5,
      }
      let l = undefined;
      let r = undefined;
      let t = undefined;
      let b = undefined;
      if (this.emplacementList[1] != undefined) { // Multiple emplacements
        for (let i in this.emplacementList) if (this.emplacementList[i].geoJSON.geometry != undefined && this.emplacementList[i].geoJSON.geometry.type === "Point") {
          if (this.emplacementList[i].geoJSON.geometry.coordinates[0] >= t || t === undefined) {
            t = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0])
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[0] <= b || b === undefined) {
            b = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0]);
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[1] >= r || r === undefined) {
            r = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1])
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[1] <= l || l === undefined) {
            l = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1]);
          }
        }
        // CENTER
        viewpoint.center.lng = (r + l) / 2;
        viewpoint.center.lat = (t + b) / 2;
      }
      else if (this.emplacementList[0] != undefined) { // Single emplacement
        viewpoint.center = { lng: parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[0]), lat: parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[1]) };
      }
      return viewpoint;
    },
    emplacementCardsByPages() {
      let emplacementCardsByPages = []
      let i = 0;
      while (i * 6 < this.emplacementList.length) {
        emplacementCardsByPages.push(this.emplacementList.slice(i * 6, i * 6 + 6));
        i++;
      }
      return emplacementCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies",'toggleConfigurationView'],
  methods: {
    // DISPLAY   
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      const index = this.displayType.indexOf(type);
      if (index !== -1) {
        this.displayType.splice(index, 1);
        if (type !== 'table') this.togglechangeDisplaySize()
        if (type === "table") {

          this.$nextTick(() => {
            this.displayEmplacementsConfigurationDataTable();
          });
        }
      } else {
        if (type !== 'table') this.togglechangeDisplaySize()
        this.displayType.push(type);


      }

      setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.emplacementCardsPage = index;
      this.setButtonFunctions();
    },
    displayEmplacementsConfigurationDataTable() {
      $("#emplacementsConfigurationDataTable").DataTable().clear();
      for (let i in this.emplacementList) {
        $("#emplacementsConfigurationDataTable").DataTable().rows.add([{
          emplacementId: this.emplacementList[i].id,
          emplacementLabel: this.emplacementList[i],
          emplacementLocation: this.emplacementList[i].location,
          emplacementType: this.emplacementList[i].type,
          emplacementGeoJSON: this.emplacementList[i].geoJSON,
        }]);
      }
      $("#emplacementsConfigurationDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },

    togglechangeDisplaySize() {
      this.fullSize = !this.fullSize;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.emplacementsMap) {
            this.emplacementsMap.invalidateSize();
          }
        }, 300);

      });
    },
    async toggleNewEmplacementModal() {
      if (this.display.newEmplacementModal) {
        await this.getEmplacementList();

      }
      this.displayEmplacementsConfigurationDataTable()
      this.display.newEmplacementModal = !this.display.newEmplacementModal;
    },
    async toggleEditEmplacementModal() {
      if (this.display.editEmplacementModal) {
        await this.getEmplacementList();
        this.editRequest = false;
        setTimeout(() => {
          this.setButtonFunctions();
        }, 100);
        /*   $("#emplacementsConfigurationDataTable").DataTable(
          {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
              { "extend": 'colvis', "className": 'btn' },
              { "extend": 'copy', "className": 'btn' },
              { "extend": 'csv', "className": 'btn' },
              { "extend": 'print', "className": 'btn me-0' },
            ],
            columns: this.emplacementsConfigurationColumns,
            select: this.dataTableOptions.select,
            language: this.dataTableOptions.language[this.session.user.language],
            stateSave: this.dataTableOptions.stateSave,
          }
        )
        $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
           */
      }
      this.displayEmplacementsConfigurationDataTable()
      this.display.editEmplacementModal = !this.display.editEmplacementModal;
    },
    async toggleDeleteEmplacementModal() {
      if (this.display.deleteEmplacementModal) {
        await this.getEmplacementList();
        setTimeout(() => {
          this.setButtonFunctions();
        }, 100);
        if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
        else this.targetEmplacementId = "";
      }
      this.displayEmplacementsConfigurationDataTable()
      this.display.deleteEmplacementModal = !this.display.deleteEmplacementModal;
    },
    displayMyEmplacementImage(src) {
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    // BUTTONS 
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetEmplacement);
        });
        $(".btnShowEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onShowEmplacement);
        });
        $(".btnEditEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onEditEmplacement);
        });
        $(".btnDeleteEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteEmplacement);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    // TARGET 
    onTargetEmplacement(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      if (this.$route.path.includes('electricalMonitoring')) {
        const currentPath = this.$route.path;
        const commandCenterPath = 'commandCenters/' + this.targetEmplacementId;
        if (!currentPath.includes(commandCenterPath)) {
          let newPath = currentPath.replace('emplacements', 'commandCenters/' + this.targetEmplacementId);
          this.$router.replace({ path: newPath });
        }
      } else {
        this.$router.replace({ params: { targetEntity: this.targetEmplacementId } });
      }
    },
    // SHOW
    onShowEmplacement(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetEmplacementId } });
      this.editRequest = false;
      this.toggleEditEmplacementModal();
    },
    // SET
    onEditEmplacement(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetEmplacementId } });
      this.editRequest = true;
      this.toggleEditEmplacementModal();
    },
    // DELETE
    async onDeleteEmplacement(event) {
      if (!this.display.deleteEmplacementModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetEmplacementId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetEmplacementId } });
      }
      else {
        let response = await api.deleteEmplacement(this.targetEmplacementId);
        console.log(response);
      }
      await this.toggleDeleteEmplacementModal();
    }
  },
  async created() {
    await this.getEmplacementList()
    this.$emit('getAvailableCompanies', this.displayEmplacementsConfigurationDataTable());
    if (this.emplacementList[0] != undefined && this.targetEmplacementId === "") this.targetEmplacementId = this.emplacementList[0].id;
    this.setButtonFunctions();
  },
  mounted() {
    $("#emplacementsConfigurationDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.emplacementsConfigurationColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    )
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover,
.btnTargetEmplacement:hover,
.btnEditEmplacement:hover,
.btnDeleteEmplacement:hover {
  color: var(--main-dark) !important;
}

#cardLabel:hover {
  color: var(--main-white) !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 0.5rem !important;
}

/* esto pasar a clomos assets */
.bg-lat {
  background: rgb(0, 25, 113);
  background: linear-gradient(90deg, rgba(0, 25, 113, 1) 35%, rgba(0, 37, 119, 1) 100%);
}

.bg-head {
  background: rgb(0, 40, 120);
  background: linear-gradient(90deg, rgba(0, 40, 120, 1) 35%, rgba(0, 148, 183, 1) 100%);
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1px 3px !important;
  /* e.g. change 8x to 4px here */
}
</style>