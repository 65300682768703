<template>
  <!-- SECTION Machines-->
  <div id="machinesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
        <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
          <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">
            <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
              <div class="row p-0 m-0">
                  <!--Primera sección-->
                <div class="row col-xl-5 col-lg-6 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                  <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                    <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                      <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                      {{ dictionary["Empresas"][session.user.language] }}
                    </h6>
                    <select  v-if="this.$route.params.currentDisplay === 'machines' || this.$route.params.currentDisplay === 'commandCenters' || this.targetEmplacementId == ''"
                      :value="currentCompany.nifCif"
                      class="form-control m-0 p-0 text-nowrap bg-white border" 
                      style="border: solid 2px white ; color: black; min-width:200px; height: 34px;"
                      @change="onCurrentCompanyChanged">
                        <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                        <option v-for="company in availableCompanies" :key="company.nifCif"
                        :v-if="company.nifCif != session.user.nifCif
                        && company.relation != undefined
                        && company.relation.referenceCompany === this.session.user.nifCif"
                        :value="company.nifCif">
                          {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                        </option>
                    </select>
                  </div>
                  <div class="row col-xl-3 col-lg-3 col-md-5 col-12 justify-content-start m-0 p-0 pt-1">
                    <button @click="onChangeDisplayType('table')" :title="dictionary['Tabla'][session.user.language]"
                      :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.7'"
                      class="button-ok bg-white mt-0 me-2 rounded shadow" style="width: 24px; max-height: 24px;">
                      <span>
                        <span class="button-text text-dark">
                          <ion-icon name="list-outline"
                            :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.5'"
                            class="border-white tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                        </span>
                      </span>
                    </button>
  
                    <button @click="onChangeDisplayType('cards')" :title="dictionary['Tarjetas'][session.user.language]"
                      :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.7'"
                      class="button-ok bg-white mt-0 me-2 rounded shadow" style="width: 24px; max-height: 24px;">
                      <span>
                        <span class="button-text text-dark">
                          <ion-icon name="documents-outline"
                            :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.5'"
                            class="border-white tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                        </span>
                      </span>
                    </button>
                    <button @click="onChangeDisplayType('blueprint')" :title="dictionary['Tarjetas'][session.user.language]"
                      :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.7'"
                      class="button-ok bg-white mt-0 me-2 rounded shadow" style="width: 24px; max-height: 24px;">
                      <span>
                        <span class="button-text text-dark">
                          <ion-icon name="locate-outline"
                            :style="(!displayType.includes('blueprint')) ? 'opacity=1;' : 'opacity: 0.5'"
                            class="border-white tx-18 pt-0" style="vertical-align: middle;"></ion-icon>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
                <!-- Fin Primera sección-->
                <!--Segunda sección-->
                <div class="row col-xl-7 col-lg-6 col-md-9 col-12 justify-content-end p-0 m-0 pe-3">
                  <div class="row col-lg-8 col-md-8 col-8 justify-content-end">
                    <span class="text-end text-white mt-1 mb-2 pe-4">
                      <ion-icon name="file-tray-stacked" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                      {{ dictionary["Máquinas"][session.user.language] }}
                    </span>
                  </div>
                  <div class="row col-xl-2 col-lg-3 col-md-3 col-4 justify-content-end p-0 m-0 pt-1 pe-3">
                    <button v-if="this.session.user.rol === '255'" id="btnDisplayNewLockerModal"
                      :title="dictionary['Añadir máquina'][session.user.language]"
                      style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                      @click="toggleNewMachineModal">
                      <span>
                        <span class="button-text text-dark">
                          <ion-icon name="add-circle-outline" class="text-dark pt-0 tx-18 hydrated"
                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                            :title="dictionary['Añadir máquina'][session.user.language]"></ion-icon>
                        </span>
                      </span>
                    </button>

                    <button id="btnDisplayEditEraseModal" style="width: 24px; max-height: 24px;"
                      :title="dictionary['Actualizar'][session.user.language]"
                      class="button-ok bg-white mt-0 me-2 rounded shadow" @click="refreshView">
                      <span>
                        <span class="button-text text-dark">
                          <ion-icon name="refresh-outline" class="text-dark pt-0 me-0 tx-16 hydrated"
                            :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                          <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

      <!-- COMPONENT Machines -->

          <!--<ion-icon v-if="session.user.bookmark==='machines'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
         

          <!-- MAP Devices -->
          <div v-show="displayType === 'map'" id="machinesMapContent" class="m-0 p-3" style="width: 100%; z-index: 0;"
            :style="(!display.showEverything) ? 'height: 15rem !important;' : 'height: 500px !important;'">
          </div>

          <!-- BLUEPRINT Machines -->
          <div v-show="displayType === 'blueprint'" id="machinesBlueprintContent" class="col-12 m-0 p-2 h-auto"
            :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <div class="blueprintCanvas col-9 m-0 p-0">
              <img id="emplacementBlueprintForMachines" class="w-100 m-0 p-2" :src="displayMachinesBlueprint()">
              <div v-for="machine in machineList" :key="machine" :data-id="machine.id"
                class="bg-caja rounded-3 p-2 machineBlueprintMarker" :style="bluePrintMarkerPosition(machine)">
                <div class="d-flex flex-column" style="min-width: 9rem;">
                  <span :data-id="machine.id" class="btnTargetMachine c-pointer tx-bold tx-grey m-0 p-0">
                    {{ machine.label }}
                  </span>
                  <div class="m-0 p-0 d-flex flex-row justify-content-between">
                    <text class="w-100"> {{ machine.location }} </text>
                    <ion-icon name="create-outline" :data-id="machine.id"
                      class="btnEditMachine col-2 m-0 ps-1 p-0 btn tx-24 tx-primary float-end"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="machine.id"
                      class="btnDeleteMachine col-2 m-0 p-0 btn tx-24 tx-danger float-end"></ion-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ORGANIGRAM Machines -->
          <div v-show="displayType === 'organigram'" :class="(displayType === 'organigram') ? 'd-flex' : ''"
            id="machinesOrganigramContent" class="col-12 m-0 p-0 h-auto"
            :style="(!display.showEverything || this.$route.params.currentModule === 'smartLockers') ? 'height: 15rem !important; overflow: scroll; overflow-x: hidden;' : ''">

            <div v-show="displayType === 'organigram' && machineOrganigram[0] == undefined"
              class="m-3 p-3 w-100 border rounded">
              <span v-if="machineOrganigramLoading" class="tx-14">
                {{ dictionary['Cargando'][session.user.language] + "..." }}
              </span>
              <span v-else class="tx-14">
                {{ dictionary['No hay dispositivos disponibles'][session.user.language] }}
              </span>
            </div>


            <div v-if="displayType === 'organigram' && machineOrganigram != undefined && machineOrganigram[0] != undefined"
              class="d-flex flex-row flex-wrap col-12 m-0 p-2"
              :style="(!display.showEverything) ? 'height: 15rem !important;' : 'height: 500px !important; overflow: scroll; overflow-x: hidden;'">

              <div v-for="machineSet in machineOrganigram" :key="machineSet"
                class="d-flex flex-column m-0 p-2 col-12 col-lg-6 col-xl-4 gap-1" style="height: 400px">
                <vue-tree :ref="'scaleTree' + machineSet.id"
                  style="width: 100%; margin: 0; height: 90%; border: 1px solid gray;" class="rounded"
                  :dataset="machineSet" :config="treeConfig" :collapse-enabled=false linkStyle="straight">
                  <template v-slot:node="{ node, collapsed }">
                    <div v-if="node.type === '2'" class="node"
                      :style="{ border: collapsed ? '2px solid lightgrey' : '' }">
                      <div class="node-top bg-primary">
                        <span :data-id="node.id"
                          class='btnTargetMachine c-pointer tx-bold tx-clomos-white text-nowrap align-middle ps-1 pe-1'>
                          {{ node.name }}
                        </span>
                      </div>

                      <div class="node-bot d-flex flex-column p-1 border-1 border-top-0 bg-white">
                        <div class="m-0 p-0 tx-14 d-flex flex-row">
                          <span class="p-1 m-0 tx-bold">
                            {{ dictionary["Estado"][session.user.language] + ":" }}
                          </span>
                          <span class="p-1 m-0">
                            {{ (node.status != "") ? node.status : "-" }}
                          </span>
                        </div>
                        <div class="m-0 p-0 tx-14 d-flex flex-row">
                          <ion-icon name='settings-outline' :data-id=node.id
                            class='btnEditMachine btn p-1 tx-24 tx-primary'></ion-icon>
                          <ion-icon name='trash-outline' :data-id=node.id
                            class='btnDeleteMachine btn p-1 tx-24 tx-danger'></ion-icon>
                        </div>
                      </div>

                    </div>

                    <div v-if="node.type === '3'" class="node"
                      :style="{ border: collapsed ? '2px solid lightgrey' : '' }">
                      <div class="node-top bg-menu">
                        <span class='tx-bold tx-clomos-white text-nowrap align-middle ps-1 pe-1'>
                          {{ node.name }}
                        </span>
                      </div>
                      <div class="node-bot d-flex flex-column p-1 border-1 border-top-0 bg-white">
                        <div class="m-0 p-0 tx-14 d-flex flex-row">
                          <span class="p-1 m-0 tx-bold">
                            {{ dictionary["Potencia activa"][session.user.language] + ":" }}
                          </span>
                          <span class="p-1 m-0">
                            {{ (node.power != "") ? node.power : "-" }}
                          </span>
                        </div>

                        <div class="m-0 p-0 tx-14 d-flex flex-row">
                          <span class="p-1 m-0 tx-bold">
                            {{ dictionary["Energía activa"][session.user.language] + ":" }}
                          </span>
                          <span class="p-1 m-0">
                            {{ (node.energy != "") ? node.energy : "-" }}
                          </span>
                        </div>
                      </div>
                    </div>

                  </template>
                </vue-tree>

                <div class="d-flex flex-row m-0 p-0 gap-1" style="width: 100%; height: 10%; margin: auto;">
                  <div class="bg-menu rounded w-100 m-0 d-flex c-pointer"
                    @click="controlScale(machineSet.id, 'smaller')">
                    <span class=" tx-bold tx-white m-auto">
                      -
                    </span>
                  </div>

                  <div class="bg-menu rounded w-100 m-0 d-flex c-pointer"
                    @click="controlScale(machineSet.id, 'restore')">
                    <span class=" tx-bold tx-white m-auto">
                      1:1
                    </span>
                  </div>

                  <div class="bg-menu rounded w-100 m-0 d-flex c-pointer"
                    @click="controlScale(machineSet.id, 'bigger')">
                    <span class=" tx-bold tx-white m-auto">
                      +
                    </span>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <!-- TABLE Machines -->
          <div v-show="displayType === 'table'" class="m-0 p-3 h-auto" id="machinesTableContent"
            :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <table id="machinesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in machinesColumns" :key="column.data" :width="column.width"
                    class="fw-medium text-nowrap p-2">
                    {{ dictionary[column.text][session.user.language] }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>

          <!-- CARDS Machines -->
          <div v-show="displayType === 'cards' && this.machineList[0] != undefined" id="machinesCardsContent"
            class="col-12 m-0 p-2 h-auto" :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <div class="col-12 m-0 p-0 d-flex flex-row flex-wrap">
              <div v-for="machine in machineCardsByPages[machineCardsPage]" :key="machine"
                class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6
                  class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2 gradient-tablas rounded-top-3">
                  <span
                    class="m-auto">{{ (machine.label.length > 18) ? (machine.label.substring(0, 18) + '...') : machine.label }}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom-3 m-0 p-3">
                  <div class="col-12 m-0 p-0 d-flex space-between">
                    <ion-icon name="pulse-outline" :data-id="machine.id"
                      class="btnTargetMachine col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="create-outline" :data-id="machine.id"
                      class="btnEditMachine col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="machine.id"
                      class="btnDeleteMachine col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                  </div>
                  <hr class="col-12 mt-2 mb-2">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                    <span class="tx-14 tx-bold">{{ dictionary['Tipo'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ machine.type }}</span>
                    <span class="tx-14 tx-bold">{{ dictionary['Identificador'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ machine.id }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page, index in machineCardsByPages" :key="page"
                  class="border rounded c-pointer m-0 me-2 tx-14" :class="(machineCardsPage === index) ? 'border-dark' : ''"
                  style="height:2.5rem; width:2.5rem; padding: inherit;" @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-show="displayType === 'cards' && this.machineList[0] == undefined" class="m-3 p-3 border rounded">
            <span class="tx-14">
              {{ dictionary['No hay máquinas disponibles'][session.user.language] }}
            </span>
          </div>

        </div>
      </div>

    </div>
  </div>

  <!-- MODAL New Machine -->
  <NewMachineModal v-if="display.newMachineModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewMachineModal="toggleNewMachineModal">
  </NewMachineModal>

  <!-- MODAL Edit Machine -->
  <EditMachineModal v-if="display.editMachineModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :machineId="targetMachineId"
    v-on:toggleEditMachineModal="toggleEditMachineModal">
  </EditMachineModal>

  <!-- MODAL Link Text Alert -->
  <LinkMachineModal v-if="display.linkMachineModal" :session="session" :dictionary="dictionary"
    :availableCompanies="availableCompanies" :currentCompany="currentCompany" :targetMachineId="targetMachineId"
    :linkAction="linkAction" v-on:dismissAlert="toggleLinkMachineModal">
  </LinkMachineModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteMachineModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Máquina a eliminar'][session.user.language] + ': '" :alertTarget="targetMachineId"
    v-on:acceptAlert="onDeleteMachine" v-on:dismissAlert="toggleDeleteMachineModal">
  </TextAlertModal>

  <!-- SECTION Bundles -->

  <BundlesComponent v-if="this.$route.params.currentDisplay!='commandCenters' && (this.$route.params.currentDisplay==='machines' || this.targetEmplacementId == '')"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :targetMachineId="targetMachineId"
  :dataTableOptions="dataTableOptions">
  </BundlesComponent>

  <!-- SECTION Devices -->
  
  <DevicesComponent v-if="this.$route.params.currentDisplay==='commandCenters'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :targetEmplacementId="(this.$route.params.currentDisplay!='commandCenters')?targetEmplacementId:''"
  :targetMachineId="targetMachineId"
  :dataTableOptions="dataTableOptions"
  v-on:setCurrentCompany="setCurrentCompany"
  v-on:getAvailableCompanies="getAvailableCompanies">
  </DevicesComponent>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import BundlesComponent from '@/components/bundles/BundlesComponent';
import DevicesComponent from '@/components/devices/DevicesComponent';
import NewMachineModal from '@/components/machines/NewMachineModal';
import EditMachineModal from '@/components/machines/EditMachineModal';
import LinkMachineModal from '@/components/machines/LinkMachineModal';
import TextAlertModal from '@/components/other/TextAlertModal';
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";
// Clomos
import api from '@/services/api';

export default {
  name: "MachinesComponent",
  components: { BundlesComponent, DevicesComponent, NewMachineModal, EditMachineModal, LinkMachineModal, TextAlertModal, VueTree },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentCompany: async function () {
      await this.getEmplacementList();
      if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
        await this.getMachinesDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getMachinesDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.machineList[0] != undefined && this.targetMachineId === "")
        this.targetMachineId = this.machineList[0].id
    },
    targetEmplacementId: async function () {
      if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
        await this.getMachinesDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getMachinesDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.machineList[0] != undefined && this.targetMachineId === "")
        this.targetMachineId = this.machineList[0].id;
    }
  },
  data() {
    return {
      machinesColumns: [
        {
          text: "Identificador", data: "machineId", width: "25%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnTargetMachine c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>" + data + "</span>";
          }
        },
        { text: "Nombre", data: "machineLabel", width: "15%", className: "tx-left align-middle" },
        { text: "Tipo", data: "machineType", width: "10%", className: "tx-left align-middle" },
        {
          text: "Fecha de comunicación", data: "machineCommunicationDateTime", width: "10%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {
          text: "Padre", data: "machineParent", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            return data.parentLabel;
          }
        },
        {
          text: "Vincular", data: "machineParent", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='log-in-outline' data-id=" + data.machineId + " class='btnLinkMachine btn tx-24 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Configuración", data: "machineId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data + " class='btnEditMachine btn tx-24 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "machineId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteMachine btn tx-24 tx-danger'></ion-icon>"
          }
        },
      ],
      targetEmplacementData: {},
      targetMachineId: this.$route.params.targetEntity,
      machineList: [],
      emplacementList: [],
      machinesMap: undefined,
      machineOrganigram: [],
      machineOrganigramLoading: false,
      treeConfig: { nodeWidth: 250, nodeHeight: 130, levelHeight: 130 },
      displayType: (this.$route.params.currentDisplay === 'commandCenters') ? "organigram" : (this.$route.params.currentView === 'emplacements') ? 'blueprint' : 'table',
      machineCardsPage: 0,
      display: {
        showEverything: true,
        newMachineModal: false,
        editMachineModal: false,
        linkMachineModal: false,
        deleteMachineModal: false,
      },
      linkAction: "link",
      emplacementLinkedId:''
    }
  },
  computed: {
    machineCardsByPages() {
      let machineCardsByPages = []
      let i = 0;
      while (i * 12 < this.machineList.length) {
        machineCardsByPages.push(this.machineList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return machineCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "setEmplacement", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    async displayMachineOrganigram() {
      this.machineOrganigramLoading = true;
      let newMachineOrganigram = [];
      let nodesToOrganize = [];
      let childResponse;
      let parent0, parent1, parent2, parent3, parent4;
      let child0, child1, child2, child3, child4;

      if (this.targetEmplacementData.children != "") {
        for (let n in this.machineList) {
          parent0 = { ...this.targetEmplacementData };
          childResponse = await api.getMachineData(this.machineList[n].id);
          child0 = api.parseMachineFromResponse(childResponse);
          if (child0.dclClass === "2") {
            // LVL 0 MACHINES
            child0.nodeLevel = n;
            nodesToOrganize.push(child0);
            if (child0.children != "") {
              let nn = 0;
              for (let childId in child0.children) {
                if (child0.children != "" && child0.children[childId].claseDcl != "2" && child0.children[childId].claseDcl != "3") {
                  nn += 1;
                  continue;
                }
                if (child0.children[childId].claseDcl === "2") {
                  parent1 = { ...child0 };
                  childResponse = await api.getMachineData(childId);
                  child1 = api.parseMachineFromResponse(childResponse);
                } else if (child0.children[childId].claseDcl === "3") {
                  parent1 = { ...child0 };
                  childResponse = await api.getDeviceData(childId);
                  child1 = api.parseDeviceFromResponse(childResponse);
                }
                if (child1.dclClass === "2" || child1.dclClass === "3") {
                  // LVL 1 MACHINES & DEVICES
                  child1.nodeLevel = n + "." + nn;
                  nodesToOrganize.push(child1);
                  if (child1.children != "") {
                    let nnn = 0;
                    for (let childId in child1.children) {
                      if (child1.children != "" && child1.children[childId].claseDcl != "3") {
                        nnn += 1;
                        continue;
                      }
                      parent2 = { ...child1 };
                      childResponse = await api.getDeviceData(childId);
                      child2 = api.parseDeviceFromResponse(childResponse);
                      if (child2.dclClass === "3") {
                        // LVL 2 DEVICES
                        child2.nodeLevel = n + "." + nn + "." + nnn;
                        nodesToOrganize.push(child2);
                        if (child2.children != "") {
                          let nnnn = 0;
                          for (let childId in child2.children) {
                            if (child2.children != "" && child2.children[childId].claseDcl != "3") {
                              nnnn += 1;
                              continue;
                            }
                            parent3 = { ...child2 };
                            childResponse = await api.getDeviceData(childId);
                            child3 = api.parseDeviceFromResponse(childResponse);
                            if (child3.dclClass === "3") {
                              // LVL 3 DEVICES
                              child3.nodeLevel = n + "." + nn + "." + nnn + "." + nnnn;
                              nodesToOrganize.push(child3);
                              if (child3.children != "") {
                                let nnnnn = 0;
                                for (let childId in child3.children) {
                                  if (child3.children != "" && child3.children[childId].claseDcl != "3") {
                                    nnnnn += 1;
                                    continue;
                                  }
                                  parent4 = { ...child3 };
                                  childResponse = await api.getDeviceData(childId);
                                  child4 = api.parseDeviceFromResponse(childResponse);
                                  if (child4.dclClass === "3") {
                                    child4.nodeLevel = n + "." + nn + "." + nnn + "." + nnnn + "." + nnnnn;
                                    nodesToOrganize.push(child4);
                                  }
                                  nnnnn += 1;
                                  if (child4.children === "" || Object.keys(child4.children).length === Number(nnnnn)) {
                                    child4 = { ...parent4 };
                                  }
                                }
                              }
                            }
                            nnnn += 1;
                            if (child3.children === "" || Object.keys(child3.children).length === Number(nnnn)) {
                              child3 = { ...parent3 };
                            }
                          }
                        }
                      }
                      nnn += 1;
                      if (child2.children === "" || Object.keys(child2.children).length === Number(nnn)) {
                        child2 = { ...parent2 };
                      }
                    }
                  }
                }
                nn += 1;
                if (child1.children === "" || Object.keys(child1.children).length === Number(nn)) {
                  child1 = { ...parent1 };
                }
              }
            }
          }
          if (child0.children === "" || Object.keys(child0.children).length === Number(n) + 1) {
            child0 = { ...parent0 };
          }
        }
      }

      while (nodesToOrganize.length > 0) {
        for (let i in nodesToOrganize) {
          let node = nodesToOrganize[i];
          // LVL 0
          if (node.nodeLevel.split(".").length === 1) {
            let lvl0 = Number(node.nodeLevel.split(".")[0]);
            let nodeStatus = (node.variables.analog["Estado"] != undefined) ? node.variables.analog["Estado"].value : '';
            newMachineOrganigram[lvl0] = {
              id: node.id,
              name: node.label,
              type: node.dclClass,
              status: nodeStatus,
              children: []
            }
            nodesToOrganize.splice(i, 1);
            continue;
          }
          // LVL 1 
          else if (node.nodeLevel.split(".").length === 2) {
            let lvl0 = Number(node.nodeLevel.split(".")[0]);
            if (newMachineOrganigram[lvl0] == undefined) continue;
            let lvl1 = Number(node.nodeLevel.split(".")[1]);
            if (node.dclClass === "2") {
              let nodeStatus = (node.variables.analog["Estado"] != undefined) ? node.variables.analog["Estado"].value : '';
              newMachineOrganigram[lvl0].children[lvl1] = {
                id: node.id,
                name: node.label,
                type: node.dclClass,
                status: nodeStatus,
                children: []
              }
            }
            if (node.dclClass === "3") {
              let nodePower = (node.variables.analog["Potencia aparente"] != undefined) ? node.variables.analog["Potencia aparente"].value : '';
              let nodeEnergy = (node.variables.analog["Energía aparente"] != undefined) ? node.variables.analog["Energía aparente"].value : '';
              newMachineOrganigram[lvl0].children[lvl1] = {
                id: node.id,
                name: node.label,
                type: node.dclClass,
                power: nodePower,
                energy: nodeEnergy,
                children: []
              }
            }
            nodesToOrganize.splice(i, 1);
            continue;
          }
          // LVL 2 
          else if (node.nodeLevel.split(".").length === 3) {
            let lvl0 = Number(node.nodeLevel.split(".")[0]);
            if (newMachineOrganigram[lvl0] == undefined) continue;
            let lvl1 = Number(node.nodeLevel.split(".")[1]);
            if (newMachineOrganigram[lvl0].children[lvl1] == undefined) continue;
            let lvl2 = Number(node.nodeLevel.split(".")[2]);
            let nodePower = (node.variables.analog["Potencia aparente"] != undefined) ? node.variables.analog["Potencia aparente"].value : '';
            let nodeEnergy = (node.variables.analog["Energía aparente"] != undefined) ? node.variables.analog["Energía aparente"].value : '';
            newMachineOrganigram[lvl0].children[lvl1].children[lvl2] = {
              id: node.id,
              name: node.label,
              type: node.dclClass,
              power: nodePower,
              energy: nodeEnergy,
              children: []
            }
            nodesToOrganize.splice(i, 1);
            continue;
          }
          // LVL 3 
          else if (node.nodeLevel.split(".").length === 4) {
            let lvl0 = Number(node.nodeLevel.split(".")[0]);
            if (newMachineOrganigram[lvl0] == undefined) continue;
            let lvl1 = Number(node.nodeLevel.split(".")[1]);
            if (newMachineOrganigram[lvl0].children[lvl1] == undefined) continue;
            let lvl2 = Number(node.nodeLevel.split(".")[2]);
            if (newMachineOrganigram[lvl0].children[lvl1].children[lvl2] == undefined) continue;
            let lvl3 = Number(node.nodeLevel.split(".")[3]);
            let nodePower = (node.variables.analog["Potencia aparente"] != undefined) ? node.variables.analog["Potencia aparente"].value : '';
            let nodeEnergy = (node.variables.analog["Energía aparente"] != undefined) ? node.variables.analog["Energía aparente"].value : '';
            newMachineOrganigram[lvl0].children[lvl1].children[lvl2].children[lvl3] = {
              id: node.id,
              name: node.label,
              type: node.dclClass,
              power: nodePower,
              energy: nodeEnergy,
              children: []
            }
            nodesToOrganize.splice(i, 1);
            continue;
          }
          // LVL 3 
          else if (node.nodeLevel.split(".").length === 5) {
            let lvl0 = Number(node.nodeLevel.split(".")[0]);
            if (newMachineOrganigram[lvl0] == undefined) continue;
            let lvl1 = Number(node.nodeLevel.split(".")[1]);
            if (newMachineOrganigram[lvl0].children[lvl1] == undefined) continue;
            let lvl2 = Number(node.nodeLevel.split(".")[2]);
            if (newMachineOrganigram[lvl0].children[lvl1].children[lvl2] == undefined) continue;
            let lvl3 = Number(node.nodeLevel.split(".")[3]);
            if (newMachineOrganigram[lvl0].children[lvl1].children[lvl2].children[lvl3] == undefined) continue;
            let lvl4 = Number(node.nodeLevel.split(".")[4]);
            let nodePower = (node.variables.analog["Potencia aparente"] != undefined) ? node.variables.analog["Potencia aparente"].value : '';
            let nodeEnergy = (node.variables.analog["Energía aparente"] != undefined) ? node.variables.analog["Energía aparente"].value : '';
            newMachineOrganigram[lvl0].children[lvl1].children[lvl2].children[lvl3].children[lvl4] = {
              id: node.id,
              name: node.label,
              type: node.dclClass,
              power: nodePower,
              energy: nodeEnergy,
              children: []
            }
            nodesToOrganize.splice(i, 1);
            continue;
          }
        }
      }
      this.machineOrganigram = newMachineOrganigram;
      this.machineOrganigramLoading = false;
      this.setButtonFunctions();
    },
    controlScale(id, command) {
      let target = this.$refs[("scaleTree" + id)][0];
      switch (command) {
        case 'bigger':
          target.zoomIn()
          break
        case 'smaller':
          target.zoomOut()
          break
        case 'restore':
          target.restoreScale()
          break
      }
    },
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onEmplacementChanged(event) {
      let value = event.target.value;
      this.$emit('setEmplacement',value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(() => {
        if (type === "map") this.displayMachinesMap();
        if (type === "blueprint") this.displayMachinesBlueprint();
        if (type === "table") this.displayMachinesDataTable();
        if (type === "organigram") this.displayMachineOrganigram();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.machineCardsPage = index;
      this.setButtonFunctions();
    },
    displayMachinesBlueprint() {
      setTimeout(() => {
        $(".machineBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.machineList) {
            if (this.machineList[i].id === markerId)
              marker.style = this.bluePrintMarkerPosition(this.machineList[i]);
          }
        })
      }, 1000);
      let src = "";
      if (this.targetEmplacementData.variables != undefined
        && this.targetEmplacementData.variables.dynamic != undefined
        && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(machine) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForMachines');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;

        let domX = machine.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7;
        let domY = machine.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;

        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY + "px; left: " + markerX + "px;"
    },
    displayMachinesMap() {
      if (this.machinesMap != undefined) {
        this.machinesMap.invalidateSize();
        this.machinesMap.remove();
      }
      var machinesMap = Leaflet.map("machinesMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(machinesMap);


      var siteMarkersGroup = new MarkerClusterGroup();
      machinesMap.addLayer(siteMarkersGroup);

      let machinesMapListForGroup = [];
      siteMarkersGroup.addLayers(this.machineList.map(m => {
        const options = { title: m.label, clickable: true, draggable: false };
        let coordinates = [0, 0];
        if (m.parent === "" || this.$route.params.currentModule === 'electricalMonitoring')
          coordinates = [m.geoJSON.geometry.coordinates[0], m.geoJSON.geometry.coordinates[1]]
        else for (let ii in this.emplacementList) if (m.parent === this.emplacementList[ii].id)
          coordinates = [this.emplacementList[ii].geoJSON.geometry.coordinates[0], this.emplacementList[ii].geoJSON.geometry.coordinates[1]]
        let emplacement = (m.emplacement != undefined && m.emplacement != "") ? m.emplacement : this.dictionary["Sin localizar"][this.session.user.language];
        var marker = Leaflet.marker(coordinates, options);
        marker.on("click", this.setButtonFunctions);
        marker.bindPopup('<div class="d-flex flex-column" style="min-width: 9rem;">'
          + '<span data-id="' + m.id + '" class="btnTargetMachine c-pointer tx-bold tx-grey m-0 p-0">' + m.label + '</span>'
          + '<div class="m-0 p-0 d-flex flex-row justify-content-between">'
          + '<text class="w-100">' + emplacement + '</text>'
          + '<ion-icon name="create-outline" data-id="' + m.id + '" class="btnEditMachine col-2 m-0 mt-auto ps-1 p-0 btn tx-24 tx-primary float-end"></ion-icon>'
          + '<ion-icon name="trash-outline" data-id="' + m.id + '" class="btnDeleteMachine col-2 m-0 mt-auto p-0 btn tx-24 tx-danger float-end"></ion-icon>'
          + '</div></div>');
        machinesMapListForGroup.push(marker);
        return marker;
      }))
      if (machinesMapListForGroup[0] != undefined) {
        var machinesMapGroup = Leaflet.featureGroup(machinesMapListForGroup);
        machinesMap.fitBounds(machinesMapGroup.getBounds());
      } else {
        machinesMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
      }

      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();

      this.machinesMap = machinesMap;
    },
    displayMachinesDataTable() {
      $("#machinesDataTable").DataTable().clear()
      for (let i in this.machineList) {
        let parentLabel = "";
        if (this.targetEmplacementId != "") {
          parentLabel = this.targetEmplacementData.label;
        }
        else {
          for (let ii in this.emplacementList) {
            if (this.emplacementList[ii].id === this.machineList[i].parent) {
              parentLabel = this.emplacementList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in this.machineList) {
            if (this.machineList[ii].id === this.machineList[i].parent) {
              parentLabel = this.machineList[ii].label;
              break;
            }
          }
        }
        $("#machinesDataTable").DataTable().rows.add([{
          machineId: this.machineList[i].id,
          machineEmplacement: this.machineList[i].parent,
          machineLabel: this.machineList[i].label,
          machineLocation: this.machineList[i].parent,
          machineType: this.machineList[i].type,
          machineParent: { parentId: this.machineList[i].parent, parentLabel: parentLabel, machineId: this.machineList[i].id },
          machineStatus: this.machineList[i].status,
          machineCommunicationDateTime: (this.machineList[i].communicationDateTime != undefined && this.machineList[i].communicationDateTime != "") ? new Date(this.machineList[i].communicationDateTime) : "",
          machineVersion: this.machineList[i].version,
        }]);
      }
      $("#machinesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($("#machinesDataTable_paginate").get()[0] != undefined) $("#machinesDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async toggleNewMachineModal() {
      if (this.display.newMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.newMachineModal = !this.display.newMachineModal;
    },
    async toggleEditMachineModal() {
      if (this.display.editMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.editMachineModal = !this.display.editMachineModal;
    },
    async toggleLinkMachineModal() {
      if (this.display.linkMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.linkMachineModal = !this.display.linkMachineModal;
    },
    async toggleDeleteMachineModal() {
      if (this.display.deleteMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }
        if (this.machineList[0] != undefined) this.targetMachineId = this.machineList[0].id;
        this.onChangeDisplayType(this.displayType);
      }
      this.display.deleteMachineModal = !this.display.deleteMachineModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetMachine);
        });
        $(".btnEditMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onEditMachine);
        });
        $(".btnLinkMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkMachine);
        });
        $(".btnUnlinkMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkMachine);
        });
        $(".btnDeleteMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteMachine);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    async refreshView(){
      if (this.$route.path.includes('commandCenters')) {
      const path = this.$route.path;
      const commandCenterId = path.split('commandCenters/')[1];
      if (commandCenterId) {
        this.emplacementLinkedId = commandCenterId;
       
      
      }
    }
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
      await this.getMachinesDataByCompany();
      this.$emit('getAvailableCompanies', (this.displayMachinesDataTable));
     
    }
    else if (this.targetEmplacementId != '') {
      await this.getEmplacementData();
      await this.getMachinesDataByEmplacement();
      this.$emit('getAvailableCompanies', this.onChangeDisplayType(this.displayType));
    } 
    if (this.machineList[0] != undefined && this.targetMachineId === "")
      this.targetMachineId = this.machineList[0].id;
    },
    // GET
    async getMachinesDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getMachinesDataByEmplacement(callback) {
      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByEmplacement(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      const isLinkedIdValid = this.emplacementList.some(emplacement => emplacement.id === this.emplacementLinkedId);     
      if (this.emplacementList[0] !== undefined) {
        const idToEmit = isLinkedIdValid && this.emplacementLinkedId.length > 0
          ? this.emplacementLinkedId
          : this.emplacementList[0].id;
        this.$emit('setEmplacement', idToEmit);
      }

      this.emplacementLinkedId = '';
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    // TARGET 
    onTargetMachine(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetMachineId } })
    },
    // SET 
    onEditMachine(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetMachineId } })
      this.toggleEditMachineModal();
    },
    // LINK 
    onLinkMachine(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetMachineId } })
      this.linkAction = "link"
      this.toggleLinkMachineModal();
    },
    // UNLINK 
    onUnlinkMachine(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetMachineId } })
      this.linkAction = "unlink"
      this.toggleLinkMachineModal();
    },
    // DELETE
    async onDeleteMachine(event) {
      if (!this.display.deleteMachineModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetMachineId);
        this.targetMachineId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetMachineId } })
      }
      else {
        let response = await api.deleteMachine(this.targetMachineId);
        if (response["status-code"] === "200") {
          window.alert("La máquina ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar la máquina.");
        }
      }
      await this.toggleDeleteMachineModal();
    }
  },
  async created() {
    if (this.$route.path.includes('commandCenters')) {
      const path = this.$route.path;
      const commandCenterId = path.split('commandCenters/')[1];
      if (commandCenterId) {
        this.emplacementLinkedId = commandCenterId;
       
      
      }
    }
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
      await this.getMachinesDataByCompany();
      this.$emit('getAvailableCompanies', (this.displayMachinesDataTable));
     
    }
    else if (this.targetEmplacementId != '') {
      await this.getEmplacementData();
      await this.getMachinesDataByEmplacement();
      this.$emit('getAvailableCompanies', this.onChangeDisplayType(this.displayType));
    } 
    if (this.machineList[0] != undefined && this.targetMachineId === "")
      this.targetMachineId = this.machineList[0].id;
    
  },
  mounted() {
    $("#machinesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.machinesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btnCopyId:hover,
.btnTargetMachine:hover,
.btnLinkMachine:hover,
.btnUnlinkMachine:hover,
.btnEditMachine:hover,
.btnDeleteMachine:hover {
  color: var(--main-dark) !important;
}

.node {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.node-top {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px 5px 0 0;
}

.node-bot {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  color: black;
  border-radius: 0 0 5px 5px;
}
</style>